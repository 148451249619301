<template>
  <div class="w-full">
    <vs-card v-if="inTabEdit">
      <template v-slot:header>
        <div class="flex items-center justify-end" v-if="canAssign">
          <!-- <h4>Associated Clinics</h4> -->
          <assign-emr-clinic @getEMRClinicsList="getAssociatedEMRClinic" />
        </div>
      </template>
      <div id="div-with-loading" class="vs-con-loading__container">
        <vs-table
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="clinics"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
          search
          :noDataText="noDataText"
        >
          <div slot="header" class="flex flex-wrap flex-grow">
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              id="pageSizeSelect"
              v-model="dataTableParams.limit"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in limitOptions"
              />
            </vs-select>

          </div>

          <template slot="thead">
            <vs-th>S. No.</vs-th>
            <vs-th sort-key="clinicName">Clinic Name</vs-th>
            <vs-th
              v-if="
                check_has_permission('removeDoctorAssociatedClinics') &&
                userTypeToEdit === 'doctor'
              "
              size="25%"
              >Action</vs-th
            >

          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
              <vs-td :data="indextr">{{ (indextr + 1) | capitalize }}</vs-td>
              <vs-td :data="item">{{
                item.clinicName ? item.clinicName : item.firstName | capitalize
              }}</vs-td>
              <vs-td
                v-if="userTypeToEdit === 'doctor'"
                :data="item"
                style="display: flex"
              >
                <vx-tooltip text="Remove Association">
                  <vs-button
                    style="float: left"
                    type="border"
                    size="small"
                    icon-pack="feather"
                    icon="icon-trash-2"
                    class="m-1"
                    @click="removeEmrDoctorHandler(item._id)"
                  ></vs-button>
                </vx-tooltip>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="m-2">
          <span class="mr-2">
            {{
              dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
            }}
            -
            {{
              totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
            }}
            of {{ totalDocs }}
          </span>
          <div class="pagination-div">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="9"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </div>
    </vs-card>
    <div
      v-else
      id="data-list-list-view"
    >
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="clinics"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap flex-grow">
          <vs-select
            placeholder="10"
            vs-multiple
            autocomplete
            v-model="dataTableParams.limit"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in limitOptions"
            />
          </vs-select>
        </div>

        <template slot="thead">
          <vs-th sort-key="firstName">S. No.</vs-th>
          <vs-th sort-key="lastName">Clinic Name</vs-th>
          <vs-th
            v-if="
              check_has_permission('removeDoctorAssociatedClinics') &&
              userTypeToEdit === 'doctor'
            "
            size="25%"
            >Action</vs-th
          >
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
            <vs-td :data="indextr">{{ (indextr + 1) | capitalize }}</vs-td>
            <vs-td :data="item">{{
              item.clinicName ? item.clinicName : item.firstName | capitalize
            }}</vs-td>
            <vs-td
              v-if="
                check_has_permission('removeDoctorAssociatedClinics') &&
                userTypeToEdit === 'doctor'
              "
              :data="item"
              style="display: flex"
            >
              <vx-tooltip text="Remove Association">
                <vs-button
                  style="float: left"
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-trash-2"
                  class="m-1"
                  @click="removeEmrDoctorHandler(item._id)"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2">
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import assignEmrClinic from "../emr-doctor/assignEmrClinicToDoctor.vue"


export default {
  name: "associated-emr-clinics",
  components: {
    "assign-emr-clinic":assignEmrClinic
  },
  props: {
    userTypeToEdit: {
      type: String,
      default: "doctor",
    },
    inTabEdit: {
      type: Boolean,
      default: false,
    },
    canAssign: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      noDataText: "Loading...",
      isMounted: false,
      totalDocs: 0,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: -1,
        page: 1,
        limit: 25,
        filter: "All",
        organizationId: "",
      },
      userId: this.$route.params.doctorId,
      clinics: [],
      limitOptions: [5, 10, 25, 50, 100],
      // organizations: [],
    };
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  methods: {
    async getAssociatedEMRClinic() {
      const res = await this.$store.dispatch("doctor/fetchDoctorEMRClinics", {
        doctorId: this.userId,
        ...this.dataTableParams,
      });

      this.clinics = res.data.data.docs;
      this.totalDocs = res.data.data.pagination.total;
      this.page = res.data.data.pagination.page;
      this.noDataText = "No Associated EMR Clinics Available";
    },
    async removeEmrDoctorHandler(id) {
      try {
        this.$vs.loading()
        await this.$store.dispatch('doctor/removeEmrDoctor', {
          clinicId: id,
          userId: this.userId
        })
        this.getAssociatedEMRClinic()
        setTimeout( ()=> {
          this.$vs.loading.close()
        }, 500)
        this.$vs.notify({
          title: 'Success',
          text: 'EMR Clinic removed successfully.',
          color: 'success'
        });
      } catch (error) {
        console.log(error)
        setTimeout( ()=> {
          this.$vs.loading.close()
        }, 500)
        this.$vs.notify({
          title: 'Error',
          text: 'Failed to remove EMR Clinic. Try again.',
          color: 'danger'
        });
      }
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getAssociatedEMRClinic();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getAssociatedEMRClinic();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active === "asc" ? 1 : -1;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getAssociatedEMRClinic();
    },
  },
  created() {
    this.userId = this.$route.params.doctorId;
    this.getAssociatedEMRClinic();
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getAssociatedEMRClinic();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getAssociatedEMRClinic();
      }
    },
    "dataTableParams.filter": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.dataTableParams.page = 1;
        this.dataTableParams.filter = newfilterVal;
        this.getAssociatedEMRClinic();
      }
    },
  }
};
</script>
