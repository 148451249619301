<template>
  <div class="w-full">
    <vs-tabs class="invoice-list">
      <vs-tab label="Clinics" v-if="check_has_permission('viewNurseAssociatedClinics')">
        <div class="w-full">
            <associated-clinics
              :inTabEdit="true"
              :userId="doctorId"
              :isDoctor="true"
              userTypeToEdit="doctor"
            />
        </div>
      </vs-tab>
      <vs-tab label="EMR Clinics">
        <div class="w-full">
          <doctor-emr-clinics
            :inTabEdit="true"
            userTypeToEdit="doctor"
          />
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import associatedClinics from "../others/associatedClinics.vue";
import doctorEMRClinics from "./doctorEMRClinics.vue";

export default {
  components: {
    associatedClinics,
    "doctor-emr-clinics": doctorEMRClinics,
  },
  data: () => ({
    doctorId: null,
  }),
  created() {
    let doctorId = this.$route.params.doctorId;
    this.doctorId = doctorId;
  },
};
</script>
