<template>
    <vs-tabs class="invoice-list">
      <vs-tab label="Doctor Details">
        <div>
          <doctor-edit
            fetchClinicsBy="superAdmin"
            :canDelete="true"
            :adminApprovalVar="true"
          />
        </div>
      </vs-tab>
      <vs-tab label="Organisation">
        <div>
          <associated-organization-table :userId="doctorId" />
        </div>
      </vs-tab>
      <vs-tab label="Clinics" name="associatedClinics">
        <div class="w-full">
          <div v-if="check_has_permission('viewNurseAssociatedClinics')">
            <doctor-clinics/>
          </div>
        </div>
      </vs-tab>
      <!-- <vs-tab label="Treatments" name="treatments">
        <vs-card v-if="check_has_permission('viewNurseTreatmentHistory')"> -->
          <!-- <template v-slot:header>
            <h4>Doctor Treatment History</h4>
          </template> -->
          <!-- <treatment-history></treatment-history>
        </vs-card>
      </vs-tab> -->
      <vs-tab label="Permissions" name="permissions">
          <PermissionSettings
            userType="doctor"
            :userId="doctorId"
          />
      </vs-tab>
    </vs-tabs>
</template>
<script>
import doctorEdit from "../../components/doctors/editDoctor.vue";
import treatmentHistory from "../../components/doctors/treatmentHistory.vue";
import AssociatedClinics from "../../components/others/associatedClinics.vue";
import AssociatedOrganizationTable from "../../components/organization/associatedOrganizationTable.vue";
import PermissionSettings from "../../components/roles-permissions/PermissionSettings.vue";
import doctorClinics from "../../components/doctors/doctorClincs.vue";

export default {
  components: {
    doctorEdit,
    treatmentHistory,
    AssociatedClinics,
    AssociatedOrganizationTable,
    PermissionSettings,
    doctorClinics,
  },
  data: () => ({
    doctorId: null,
  }),
  created() {
    let doctorId = this.$route.params.doctorId;
    this.doctorId = doctorId;
  },
};
</script>

<style>
 .con-ul-tabs {
  overflow-x: auto;
  padding-bottom: 0.5rem;
 }
</style>
