<template>
  <div>
    <vs-button @click="popupActivo=true" color="primary" type="filled">Assign Clinic</vs-button>
    <vs-popup  title="Lorem ipsum dolor sit amet" :active.sync="popupActivo">
      <!-- center items in div -->
      <div class="w-full min flex flex-wrap justify-center items-center p-4">
        <!-- with same as div above -->
        <vs-select  v-model="emrClinic" label="Assign Clinic" placeholder="Select Doctor" class="w-full" autocomplete>
          <vs-select-item
            v-for="item in emrClinicsList"
            :key="item.id"
            :value="item.id"
            :text="item.clinicName"
          />
        </vs-select>
        <div class="w-full flex justify-end mt-4">
          <vs-button color="primary" type="border" @click="resetFields">Cancel</vs-button>
          <span class="assignDoctor ml-4">
            <vs-button color="primary" type="filled" @click="assignDoctor">Assign</vs-button>
          </span>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  name: 'assign-emr-clinic',
  data() {
    return {
      popupActivo: false,
      emrClinicsList: [],
      emrClinic: null,
    }
  },
  methods: {
    assignDoctor() {
      try {
        this.$vs.loading()
        this.$store.dispatch('doctor/assignEmrDoctor', {
          clinicId: this.emrClinic,
          userId: [this.$route.params.doctorId]
        })
        setTimeout( ()=> {
          this.resetFields()
          this.$vs.loading.close()
        }, 500)
        this.$vs.notify({
          title: 'Success',
          text: 'Assigned doctors successfully.',
          color: 'success'
        });
      } catch (error) {
        console.log(error)
        setTimeout( ()=> {
          this.$vs.loading.close()
        }, 500)
        this.$vs.notify({
          title: 'Error',
          text: 'Failed to assign doctors. Try again.',
          color: 'danger'
        });
      }
    },
    async getClinics() {
      const response = await this.$store.dispatch('doctor/fetchAssignEmrClinics', this.$route.params.doctorId)
      this.emrClinicsList = response.data.data
    },
    resetFields() {
      this.$emit("getEMRClinicsList");
      this.emrClinic = []
      this.popupActivo = false
    }
  },
  created() {
    this.getClinics()
  },
}
</script>

<style>
  .assignDoctor .vs-button{
    border: 1px solid rgba(var(--vs-primary),1);
    padding: .679rem 2rem !important;
  }
</style>
